<template>
  <div class="wallet-list wrap px-3 py-5">
    <div class="d-flex flex-wrap align-top mb-sm-7 mb-4">
      <div class="me-sm-0 me-5">
        <div class="text--text h7">Total Balance:</div>
        <div class="h2">SAR {{ data.amount_total ? (data.amount_total / 100).toFixed(2) : 0 }}</div>
      </div>
      <v-divider vertical class="mx-10 d-sm-inline-flex d-none"></v-divider>
      <div class="me-sm-0 me-2">
        <div class="text--text h7">Available Balance:</div>
        <div class="h2">SAR {{ data.amount_available ? (data.amount_available / 100).toFixed(2) : 0 }}</div>
      </div>
      <v-spacer></v-spacer>
      <v-btn @click="modal = true" class="mt-sm-0 mt-5" depressed color="primary" height="34" width="140">WITHDRAW</v-btn>
    </div>
    <v-data-table class="d-sm-block d-none" hide-default-footer fixed-header :headers="headers" :items="table.results">
      <template v-slot:header.status>
        Status
        <v-menu bottom open-on-hover transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="pa-0 text--text" height="20" min-width="20" x-small text v-bind="attrs" v-on="on">
              <v-icon style="font-size: 16px">mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="item in statusList" :key="item.name" class="d-flex align-center">
              <v-card flat tile width="200" class="h11 text--text me-3">{{ item.text }}</v-card>
              <div class="status" :class="getColorClass(item.title)">{{ item.title }}</div>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.id" height="52" class="link" @click="openItem(item.id)">
            <td>{{ item.number }}</td>
            <td>{{ new Date(item.date).toLocaleString() }}</td>
            <td class="text-capitalize">{{ item.type }}</td>
            <td>
              <div v-if="item.student">{{ item.student.first_name }} {{ item.student.last_name }}</div>
            </td>
            <td>
              <div v-if="item.withdrawal_status" class="status ml-auto" :class="getColorClass(item.withdrawal_status)">
                {{ item.withdrawal_status }}
              </div>
            </td>
            <td class="text-end text-uppercase">{{ item.currency }} {{ (item.amount / 100).toFixed(2) }}</td>
          </tr>
        </tbody>
      </template>
      <template v-slot:footer v-if="table.pages > 1">
        <v-divider></v-divider>
        <v-row class="mt-2 pb-2 px-10" align="center" justify="end">
          <div>
            <v-btn icon color="secondary" class="mr-1" @click="formerPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <span class="mx-4 grey--text">{{ page }} of {{ table.pages }}</span>
            <v-btn icon color="secondary" class="ml-1" @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-row>
      </template>
    </v-data-table>
    <div class="d-sm-none">
      <div v-for="item in table.results" :key="item.id" class="px-4 pt-2">
        <div class="d-flex justify-space-between my-1">
          <div>№</div>
          <div>{{ item.number }}</div>
        </div>
        <div class="d-flex justify-space-between my-1">
          <div>Date</div>
          <div>{{ new Date(item.date).toLocaleString() }}</div>
        </div>
        <div class="d-flex justify-space-between my-1">
          <div>Type</div>
          <div class="text-capitalize">{{ item.type }}</div>
        </div>
        <div class="d-flex justify-space-between my-1">
          <div>Student name</div>
          <div v-if="item.student">{{ item.student.first_name }} {{ item.student.last_name }}</div>
        </div>
        <div class="d-flex justify-space-between my-1">
          <div>Status</div>
          <div v-if="item.withdrawal_status" class="status" :class="getColorClass(item.withdrawal_status)">
            {{ item.withdrawal_status }}
          </div>
        </div>
        <div class="d-flex justify-space-between my-1">
          <div>Amount</div>
          <div class="text-uppercase">{{ item.currency }} {{ (item.amount / 100).toFixed(2) }}</div>
        </div>
        <v-divider class="mt-2"></v-divider>
      </div>
      <v-row class="mt-2 pb-2 px-10" align="center" justify="end" v-if="table.pages > 1">
        <div>
          <v-btn icon color="secondary" class="mr-1" @click="formerPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span class="mx-4 grey--text">{{ page }} of {{ table.pages }}</span>
          <v-btn icon color="secondary" class="ml-1" @click="nextPage">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-row>
    </div>
    <v-dialog v-model="modal" width="368">
      <v-card class="pa-6">
        <div class="h2 mb-1">Withdraw</div>
        <div class="h9 text--text mb-4">Available: SAR {{ data.amount_available ? (data.amount_available / 100).toFixed(2) : 0 }}</div>
        <div class="h9">Enter amount</div>
        <v-text-field
          class="field44"
          v-model="amount"
          :error-messages="amountErrors"
          placeholder="SAR"
          outlined
          dense
          color="secondary"
          height="44"
        ></v-text-field>
        <div class="text-right">
          <v-btn @click="modal = false" text width="140" height="34">Cancel</v-btn>
          <v-btn @click="action" :disabled="!amount" class="primary ms-3" width="140" height="34">CONFIRM</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      amount: '',
      statusList: [
        { title: 'submitted', text: 'The withdrawal request submitted' },
        { title: 'accomplished', text: 'The money is transferred to the Tutor payment card/bank account' },
        { title: 'rejected', text: 'The money withdrawal request rejected' },
      ],
      page: this.$route.query.page || 1,
      headers: [
        { text: '№', value: 'number', sortable: false },
        { text: 'Date', value: 'date', sortable: false },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Student name', value: 'student', sortable: false },
        { text: 'Status', value: 'withdrawal_status', sortable: false },
        { text: 'Amount', align: 'end', value: 'amount', sortable: false },
      ],
      error: [],
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getWalletAmount');
  },
  methods: {
    getColorClass(block) {
      if (block == 'submitted') {
        return 'amber lighten-3 text--text';
      } else if (block == 'rejected') {
        return 'error lighten-4 error--text';
      } else if (block == 'accomplished') {
        return 'light-green lighten-3 text--text';
      } else {
        return 'd-none';
      }
    },
    async getData() {
      this.error = [];
      await this.$store.dispatch('getWalletList', this.page).catch((e) => {
        this.getDataError(e);
      });
    },
    getDataError(e) {
      this.error = e.response.data.error;
      if (this.error.find((item) => item == 'page__out_of_bounds')) {
        this.page = 1;
        this.getData();
      }
    },
    nextPage() {
      if (this.page != this.table.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async action() {
      this.error = [];
      await this.$store
        .dispatch('postWalletAmount', { amount: this.amount })
        .then(() => {
          this.modal = false;
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
  },
  computed: {
    data() {
      return this.$store.getters.walletAmount;
    },
    table() {
      return this.$store.getters.walletList;
    },
    amountErrors() {
      const errors = [];
      this.error.find((item) => item == 'amount__required') && errors.push('Please enter your amount');
      this.error.find((item) => item == 'amount__invalid') && errors.push('Provided amount is not valid');
      this.error.find((item) => item == 'amount__too_big') && errors.push('Sorry, but amount too big');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setWalletAmount', {});
    this.$store.dispatch('setWalletList', {});
  },
};
</script>

<style scoped>
.status {
  width: max-content;
  text-transform: capitalize;
  font-size: 11px;
  padding: 2px 6px;
  border-radius: 4px;
  line-height: 19px;
}
</style>
